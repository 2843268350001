import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import PortableText from 'react-portable-text';

import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.workwaveBlue,
    padding: '0px',
    borderRadius: '20px',
    minHeight: '110px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    // maxWidth: '289px',
    position: 'relative',
    // paddingTop: '2.75rem',
    // [theme.breakpoints.down('xs')]: {
    // 	maxWidth: '100% !important',
    // 	marginLeft: '0px',
    // 	marginRight: '0px',
    // 	width: '100%',
    // },
    // [theme.breakpoints.down('sm')]: {
    // 	maxWidth: '263px',
    // },
    [theme.breakpoints.down('md')]: {
      maxWidth: '500px',
      minHeight: '90px',
    },
    // [theme.breakpoints.up('lg')]: {
    // 	maxWidth: '289px',
    // },
    transition: 'transform 0.25s',
    // boxShadow:
    // 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
  },
  title: {
    fontWeight: 600,
    color: theme.white,
    paddingBottom: '.75rem',
    textTransform: 'uppercase',
  },
  pos: {
    textAlign: 'center',
    lineHeight: 1.8,
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1.4,
    },
  },
  discount: {
    borderRadius: '10px 10px 0px 0px',
    minHeight: '60px',
    width: '250px',
    // [theme.breakpoints.down('md')]: {
    // 	maxWidth: '35%',
    // },
  },
  cardBody: {
    // marginTop: '15px',
    minHeight: '85px',
    [theme.breakpoints.down('md')]: {
      // minHeight: '90px',
    },
  },
  keyFeaturesBg: {
    backgroundColor: '#F4F8FF',
    //marginBottom: '1rem',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  keyFeatures: {
    textTransform: 'uppercase',
    color: theme.workwaveBlue,
    fontSize: '1.375rem',
    fontWeight: 600,
    lineHeight: 1.43,
  },
  featureTitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(),
    lineHeight: 1.4,
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1.0,
    },
  },
  button: {
    cursor: 'pointer',
    opacity: 1,
    textDecoration: 'none',
    transition: 'all .5s',
		color: 'white',
		margin: '2rem 0',
		position: 'absolute',
		bottom: 0,
		[theme.breakpoints.down('sm')]: {
      position: 'relative',
			margin: '1rem 0',
    },
    '&:hover': {
      opacity: 0.8,
    },
  },

  longCardBody: {
    minHeight: '723px',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    alignItems: 'space-evenly',
    justifyContent: 'flex-start',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
  },
}));

export const PricingLongCard = ({ card, index, softwareFeatures }) => {
  const classes = useStyles();
  //const lg = useMediaQuery('(max-width: 1280px)');
  const xs = useMediaQuery('(max-width: 481px)');

  const { title, header, content, icon, accentColor, discount } = card;
  const { comparisonHeader, features } = softwareFeatures;
  const columnNum = index;

  const { handleModalClick } = useContext(FormModalContext);
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.discount}
        style={{
          border: discount ? '4px solid #002D5C' : 'none',
          borderBottom: '0px',
        }}
      >
        <Typography variant="h5" color="primary" style={{ fontWeight: 700 }}>
          {discount}
        </Typography>
      </Grid>

      <Card className={classes.root} elevation={0}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{
            top: 0,
            borderTop: `16px solid ${card.accentColor.hexValue ?? '#002D5C'}`,
            background: card.accentColor.hexValue,
            textAlign: 'center',
            height: '100%',
          }}
        >
          <Typography
            variant="h4"
            className={classes.title}
          >
            {title}
          </Typography>
        </Grid>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 24px 0 24px',
            alignItems: 'space-evenly',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            container
            direction={xs ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
          >
            <FontAwesomeIcon
              icon={['fad', icon]}
              style={{
                color: accentColor.hexValue ?? '#002D5C',
                height: '60px',
                width: '60px',
                border: `1px solid #E5EAF4`,
                borderRadius: '8px',
                background: '#E5EAF4',
                padding: '10px',
                textAlign: 'center',
                marginRight: '15px',
                marginBottom: xs ? '1rem' : 0,
              }}
            />{' '}
            <Typography
              variant="h4"
              color="primary"
              style={{ fontWeight: 700 }}
            >
              {header}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.cardBody}
          >
            <Typography variant="body1" className={classes.pos}>
              {content}
            </Typography>
          </Grid>
        </CardContent>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.keyFeaturesBg}
        >
          <Typography variant="body1" className={classes.keyFeatures}>
            {comparisonHeader}
          </Typography>
        </Grid>
        <CardContent className={classes.longCardBody}>
          {features.slice(0, 18).map((feature, index) =>
            columnNum === 0
              ? feature.colOne && (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: '1rem' }}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      style={{
                        background: '#E5EAF4',
                        borderRadius: '8px',
                        padding: '4px',
                        color: '#002D5C',
                        marginRight: '1rem',
                        width: '25px',
                        height: '25px',
                      }}
                    />
                    <Typography
                      variant="body1"
                      className={classes.featureTitle}
                    >
                      {feature.featureTitle}
                    </Typography>
                  </Grid>
                )
              : columnNum === 1
              ? feature.colTwo && (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: '1rem' }}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      style={{
                        background: '#E5EAF4',
                        borderRadius: '8px',
                        padding: '4px',
                        color: '#002D5C',
                        marginRight: '1rem',
                        width: '25px',
                        height: '25px',
                      }}
                    />
                    <Typography
                      variant="body1"
                      className={classes.featureTitle}
                    >
                      {feature.featureTitle}
                    </Typography>
                  </Grid>
                )
              : feature.colThree && (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: '1rem' }}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      style={{
                        background: '#E5EAF4',
                        borderRadius: '8px',
                        padding: '4px',
                        color: '#002D5C',
                        marginRight: '1rem',
                        width: '25px',
                        height: '25px',
                      }}
                    />
                    <Typography
                      variant="body1"
                      className={classes.featureTitle}
                    >
                      {feature.featureTitle}
                    </Typography>
                  </Grid>
                )
          )}
          <Grid container alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              onClick={handleModalClick}
              className={classes.button}
              style={{
                background: accentColor.hexValue ?? '#002D5C',

              }}
            >
              GET STARTED
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
